<template>
  <div id="ignoreFontUpscale" class="mainParentContainer ignoreFontUpscale">
    <NavBarren @nav="previousPage(false)">
      <div class="tw-flex">
        <transition name="slowFade">
          <DraftSavedMessage
            v-if="DISPLAY_DRAFT_SAVED"
            key="draftSavedMessage"
            class="inMobileNav"
          />
          <div v-else key="step3">
            {{
              $t('hub.stepper.mobileStep', {
                step: 3,
                stepsCount: 4,
                description: $t('draft.progressBar.step3'),
              })
            }}
          </div>
        </transition>
      </div>
    </NavBarren>
    <DraftMessages @cancel="previousPage" />
  </div>
</template>

<script lang="ts">
import { mapActions, mapState } from 'pinia'

import DraftSavedMessage from '~/components/draft/autosave/DraftSavedMessage.vue'
import DraftMessages from '~/components/draft/messages.vue'
import NavBarren from '~/components/nav/barren.vue'

import { useNavigateToPreviousPage } from '~/composables/useNavigateToPreviousPage'

import { useDraftStore } from '~/stores/draft'
import { useInfluencersStore } from '~/stores/influencers'
import { useMiscDraftStore } from '~/stores/miscDraft'
import { useTagStore } from '~/stores/tag'

export default defineNuxtComponent({
  components: {
    DraftMessages,
    DraftSavedMessage,
    NavBarren,
  },
  async setup() {
    definePageMeta({
      middleware: ['is-logged-in', 'is-band', 'draft-influencers', 'draft-id'],
    })

    const { navigateToPreviousPage } = useNavigateToPreviousPage()
    const { FETCH: FETCH_TAGS } = useTagStore()
    const localePath = useLocalePath()

    await callOnce('FETCH_TAGS', FETCH_TAGS)

    return { navigateToPreviousPage, localePath }
  },
  computed: {
    ...mapState(useDraftStore, {
      DRAFT_ID: 'id',
      DRAFT_INFLUENCERS_COUNT: 'influencers_count',
      DRAFT_INFLUENCERS: 'influencers',
    }),
    ...mapState(useInfluencersStore, { INFLUENCERS_LIST: 'list' }),
    ...mapState(useMiscDraftStore, {
      DISPLAY_DRAFT_SAVED: 'displayDraftSaved',
    }),
    ...mapState(useRoutingStore, ['PREVIOUS']),
  },
  methods: {
    ...mapActions(useDraftStore, {
      GET_CURRENT_DRAFT: 'GET_CURRENT',
      FETCH_MISSING_INFLUENCERS: 'FETCH_MISSING_INFLUENCERS',
    }),
    ...mapActions(useInfluencersStore, ['FETCH_SET']),
    navigateToLoadedCatalog() {
      const catalogPath = `/draft/${this.DRAFT_ID}/influencers/`
      const previousStoredRoute = this.PREVIOUS
      return previousStoredRoute.includes(catalogPath)
        ? navigateTo(previousStoredRoute)
        : navigateTo(this.localePath(catalogPath))
    },
    previousPage(splitView = true) {
      return splitView
        ? this.navigateToPreviousPage('/draft/homepage/')
        : this.navigateToLoadedCatalog()
    },
  },
})
</script>

<style scoped src="./subpageShared.css"></style>
