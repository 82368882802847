import payloadToJson_f85A5nYSY0 from "/root/front/plugins/payloadToJson.ts";
import revive_payload_client_fgu2eiUmEb from "/root/front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.15_eslint@8.57.1_ioredis@5.4.1_magicast@0_bl2expzbkqbcxtb7q5dsniute4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Y89x0GBcFe from "/root/front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.15_eslint@8.57.1_ioredis@5.4.1_magicast@0_bl2expzbkqbcxtb7q5dsniute4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_kMdNXhY1j2 from "/root/front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.15_eslint@8.57.1_ioredis@5.4.1_magicast@0_bl2expzbkqbcxtb7q5dsniute4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_QvfUdt2pep from "/root/front/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.21.3_vite@5.4.8_@types+node@20.14.15_sass@1.7_tfkqmpe6uduxpqlglmj4lzf33q/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_ZiIgfNjn7D from "/root/front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.15_eslint@8.57.1_ioredis@5.4.1_magicast@0_bl2expzbkqbcxtb7q5dsniute4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_BQODM6c474 from "/root/front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.15_eslint@8.57.1_ioredis@5.4.1_magicast@0_bl2expzbkqbcxtb7q5dsniute4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_UBt0bhQb4Z from "/root/front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.15_eslint@8.57.1_ioredis@5.4.1_magicast@0_bl2expzbkqbcxtb7q5dsniute4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_EcWsmkZEf6 from "/root/front/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.21.3_typescript@5.6.2_vue@3.5.6_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/root/front/.nuxt/components.plugin.mjs";
import prefetch_client_9hXU6P8iMa from "/root/front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.15_eslint@8.57.1_ioredis@5.4.1_magicast@0_bl2expzbkqbcxtb7q5dsniute4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_X4IcK1BOy6 from "/root/front/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.21.3_vue@3.5.6_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_1jRe505ko2 from "/root/front/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.21.3_vue@3.5.6_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_wy0B721ODc from "/root/front/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import polyfills_client_xo6BY28vLt from "/root/front/plugins/polyfills.client.ts";
import globalNuxtLink_8h7uhS7dNV from "/root/front/plugins/globalNuxtLink.ts";
import sentry_3AyO8nEfhE from "/root/front/plugins/sentry.ts";
import fetch_14DdzPiXUB from "/root/front/plugins/fetch.ts";
import pinia_48xmdi2HHl from "/root/front/plugins/pinia.ts";
import globalMixin_0vW6V1IUxN from "/root/front/plugins/globalMixin.ts";
import initCsrf_GudLqtAxMz from "/root/front/plugins/initCsrf.ts";
import segment_xRnKTSkpmU from "/root/front/plugins/segment.ts";
import segmentPageTracking_CDhc5tQIUy from "/root/front/plugins/segmentPageTracking.ts";
import closeCookiebotDialog_wHIJHWT4P3 from "/root/front/plugins/closeCookiebotDialog.ts";
import growthBook_T0cxi0ahK4 from "/root/front/plugins/growthBook.ts";
import stripe_6FZUdDHpIZ from "/root/front/plugins/stripe.ts";
import vueGtag_qdVBVzWkdt from "/root/front/plugins/vueGtag.ts";
import preview_6oGpsaen2C from "/root/front/plugins/preview.ts";
export default [
  payloadToJson_f85A5nYSY0,
  revive_payload_client_fgu2eiUmEb,
  unhead_Y89x0GBcFe,
  router_kMdNXhY1j2,
  _0_siteConfig_QvfUdt2pep,
  payload_client_ZiIgfNjn7D,
  navigation_repaint_client_BQODM6c474,
  chunk_reload_client_UBt0bhQb4Z,
  plugin_vue3_EcWsmkZEf6,
  components_plugin_KR1HBZs4kY,
  prefetch_client_9hXU6P8iMa,
  switch_locale_path_ssr_X4IcK1BOy6,
  i18n_1jRe505ko2,
  plugin_wy0B721ODc,
  polyfills_client_xo6BY28vLt,
  globalNuxtLink_8h7uhS7dNV,
  sentry_3AyO8nEfhE,
  fetch_14DdzPiXUB,
  pinia_48xmdi2HHl,
  globalMixin_0vW6V1IUxN,
  initCsrf_GudLqtAxMz,
  segment_xRnKTSkpmU,
  segmentPageTracking_CDhc5tQIUy,
  closeCookiebotDialog_wHIJHWT4P3,
  growthBook_T0cxi0ahK4,
  stripe_6FZUdDHpIZ,
  vueGtag_qdVBVzWkdt,
  preview_6oGpsaen2C
]