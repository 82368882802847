import { acceptHMRUpdate, defineStore, getActivePinia } from 'pinia'

import { useDraftTrackTagsStore } from './draftTrackTags'
import { useMiscTrackStore } from './miscTrack'

import { resetStoreToInitialState } from '~/helpers/resetStoreToInitialState'

import type { TrackTagsState } from './draftTrackTags'
import type Track from '~/types/track'
import type { TrackSource } from '~/types/track'

export const draftTrackInitialState = () => ({
  id: 0,
  band: 0,
  info: '',
  link: '',
  ep_link: '',
  event: '',
  secondary_link: '',
  name: '',
  release_date: null as null | string,
  is_demo: false,
  is_exclu: false,
  is_public: false,
  created_from_profile: true,
  generated_from: 'profile' as TrackSource,
  is_archived: false,
  is_boosted: false,
  album_cover: null as null | string,
  album_link: null as null | string,
  files_update_timestamp: null as null | string,
  extra_pictures: [] as string[],
  press_release: null as null | string,
  track_file: null as null | string,
  in_previous_campaign: false,
})

const state = draftTrackInitialState

export type IDraftTrackState = ReturnType<typeof state>

export interface DraftTrackState extends IDraftTrackState {
  tags: TrackTagsState
}

export const useDraftTrackStore = defineStore('draftTrack', {
  state: (): IDraftTrackState => ({ ...draftTrackInitialState() }),
  actions: {
    UPDATE(track: Record<keyof DraftTrackState, any>) {
      const ignoreType: string[] = []
      // let key: keyof DraftTrackState
      // handled by tags store, this empties them at track create
      delete track.tags
      for (const key in this) {
        if (
          ignoreType.includes(key) ||
          (track[key as keyof DraftTrackState] !== null &&
            track[key as keyof DraftTrackState] !== undefined)
        )
          // TODO: check this
          // @ts-expect-error
          this[key] = track[key]
      }
    },
    FETCH(trackId: number): Promise<Track | null> {
      const draftTrackTagsStore = useDraftTrackTagsStore(getActivePinia())

      return new Promise((resolve, reject) => {
        Promise.all([this.GET(trackId), draftTrackTagsStore.FETCH(trackId)])
          .then(([track]) => {
            if (track) {
              // convert is_boosted to boolean
              track.is_boosted = Boolean(track.is_boosted)
              this.UPDATE(track as DraftTrackState)
            }

            resolve(track)
          })
          .catch(reject)
      })
    },
    async GET(trackId: number) {
      try {
        // TODO: check this type usage
        return await $coreFetch.$get<Track>(`/track/track/${trackId}/`)
      } catch (err) {
        return null
      }
    },
    UPDATE_ON_SERVER(track: Partial<Record<keyof DraftTrackState, any>>) {
      const miscTrackStore = useMiscTrackStore()

      if (track.release_date !== undefined) {
        track.release_date = track.release_date?.length
          ? track.release_date
          : null
      }

      if (track.id) {
        return this.SERVER_PATCH(track)
      } else {
        if (miscTrackStore.isTrackBeingCreated)
          return Promise.resolve(undefined)

        return this.SERVER_POST(track)
      }
    },
    SERVER_POST(track: Partial<Track>): Promise<Track> {
      const miscTrackStore = useMiscTrackStore()

      miscTrackStore.SET_IS_TRACK_BEING_CREATED(true)
      return $coreFetch
        .$post<DraftTrackState>('/track/track/', {
          ...track,
        })
        .then((track) => {
          this.UPDATE(track)
          return track
        })
        .finally(() => miscTrackStore.SET_IS_TRACK_BEING_CREATED(false))
    },
    SERVER_PATCH(
      track: Partial<Record<keyof DraftTrackState, any>>,
    ): Promise<Track> {
      return $coreFetch
        .$patch<Track>(`/track/track/${track.id}/`, {
          ...track,
        })
        .then((track) => {
          if (track.release_date === null) track.release_date = ''

          this.UPDATE(track as DraftTrackState)
          return track
        })
    },
    RESET() {
      resetStoreToInitialState.bind(this)(draftTrackInitialState())
    },
  },
  getters: {
    // TODO clean out and just import draftTrackInitialState instead where used out of mapGetters
    INITIAL_STATE() {
      return draftTrackInitialState()
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useDraftTrackStore, import.meta.hot))
